import { useMemo } from "react";

import { feature } from "topojson-client";
import { Topology, GeometryCollection } from "topojson-specification";
import CountriesJson from "../countries.json";

export type CountryFeatureProps = {
  name: string;
  iso3: string;
};

export function useCountryData() {
  return useMemo(() => {
    const countries = loadCountryData();
    const countryNamesByCode = countries.features.reduce((acc, cur) => {
      const countryProps = cur.properties as CountryFeatureProps;
      return {
        ...acc,
        [countryProps.iso3]: countryProps.name,
      };
    }, {} as { [countryIso3: string]: string });

    return { countries, countryNamesByCode };
  }, []);
}

export function loadCountryData() {
  return feature(
    (CountriesJson as unknown) as Topology,
    (CountriesJson.objects.countries as unknown) as GeometryCollection
  );
}
