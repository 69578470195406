import React from "react";
import { ScaleLinear } from "d3-scale";
import { Formatter } from "./types";
import { tickLength } from "./dimensions";
import classes from "./YAxis.module.css";
import axesClasses from "./Axes.module.css";
import classnames from "classnames";

type Props = {
  y: ScaleLinear<number, number>;
  maxSvgX: number;
  formatter: Formatter;
  renderGuideLines?: boolean;
};

function YAxis({ y, maxSvgX, formatter, renderGuideLines }: Props) {
  const valueTicks = y.ticks(3);
  return (
    <>
      {valueTicks.map((v) => {
        const label = formatter(v);
        return (
          <g key={`y-tick/${v}`}>
            <line
              x1={0}
              x2={-tickLength}
              y1={y(v)!}
              y2={y(v)!}
              className={axesClasses.line}
            />
            {renderGuideLines && v !== 0 && (
              <line
                strokeDasharray="5,15"
                className={classnames(axesClasses.line, classes.fineDash)}
                x1={0}
                x2={maxSvgX}
                y1={y(v)}
                y2={y(v)}
              />
            )}

            <text
              className={classes.tickLabel}
              x={-(tickLength * 2 + label.length * 8)}
              y={y(v)! + 5}
            >
              {label}
            </text>
          </g>
        );
      })}
    </>
  );
}

export default React.memo(YAxis);
