import React from "react";
import { Series } from "./charts/types";
import classes from "./Legend.module.css";

export type LegendProps = {
  series: Series[];
};

function Legend(props: LegendProps) {
  return (
    <div className={classes.container}>
      {props.series.map((series) => (
        <div key={series.label} className={classes.legendGroup}>
          <div
            className={classes.icon}
            style={{ backgroundColor: series.color }}
          ></div>
          <h4 className={classes.label}>{series.label}</h4>
        </div>
      ))}
    </div>
  );
}

export default React.memo(Legend);
