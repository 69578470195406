import formatShortDate from "./formatShortDate";

export default function formatShortRange(from: Date, to: Date): string {
  return `${formatShortDate(from)} - ${formatShortDate(to)}`;
}

export function add6days(d: Date): Date {
  const result = new Date(d.valueOf());
  result.setDate(d.getDate() + 6);
  return result;
}
