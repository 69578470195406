import InMobi from "./InMobi-logo.png";
import OpenX from "./Open-X-logo.png";
import Pubnative from "./Pubnative-logo.png";
import TripleLift from "./TripleLift.png";
import AdColony from "./adcolony-logo.png";
import Admixer from "./admixer.png";
import Adpie from "./adpie.png";
import Adpopcorn from "./adpopcorn.png";
import Applovin from "./applovin-logo.png";
import Appodeal from "./appodeal.png";
import Axonix from "./axonix-logo.png";
import Cauly from "./cauly.png";
import Chartboost from "./chartboost.png";
import Exelbid from "./exelbid.png";
import Fluct from "./fluct-logo.png";
import Fyber from "./fyber-logo.png";
import Google from "./google-logo.png";
import IndexExchange from "./indexexchange.png";
import IronSource from "./ironsource.png";
import Magnite from "./magnite.png";
import Mobfox from "./mobfox-logo.png";
import Nhance from "./nhnace.png";
import Pubmatic from "./pubmatic-logo.png";
import Smaato from "./smaato-logo.png";
import Verizon from "./verizon.png";

export default [
  Google,
  Applovin,
  Chartboost,
  Fyber,
  Appodeal,
  InMobi,
  OpenX,
  Pubnative,
  TripleLift,
  AdColony,
  Admixer,
  Adpie,
  Adpopcorn,
  Axonix,
  Cauly,
  Exelbid,
  Fluct,
  IndexExchange,
  IronSource,
  Magnite,
  Mobfox,
  Nhance,
  Pubmatic,
  Smaato,
  Verizon,
];
