import { useCallback, useState } from "react";
import isSnapshotting from "./isSnapshotting";

const localStorageKey = "introRead";

export default function useIntroState() {
  const [showIntro, setShowIntro] = useState(() => {
    if (isSnapshotting()) {
      return false;
    }

    const introRead = window.localStorage.getItem(localStorageKey);
    return !introRead && !isPortraitMobile();
  });

  const openIntro = useCallback(() => {
    setShowIntro(true);
  }, [setShowIntro]);

  const closeIntro = useCallback(() => {
    setShowIntro(false);
    if (window.localStorage) {
      window.localStorage.setItem(localStorageKey, "true");
    }
  }, [setShowIntro]);

  return { showIntro, openIntro, closeIntro };
}

export function isPortraitMobile() {
  return window.innerWidth < 800 && window.innerHeight > window.innerWidth;
}
