import React from "react";
import classes from "./Newsletter.module.css";
import classnames from "classnames";
import CallToActionLink from "./CallToActionLink";

function Newsletter({ className }: { className?: string }) {
  return (
    <div className={classnames(classes.container, className)}>
      <h3 className={classes.description}>
        Stay updated on our <span className={classes.highlight}>news</span>,{" "}
        <span className={classes.highlight}>products</span>,{" "}
        <span className={classes.highlight}>events</span> and{" "}
        <span className={classes.highlight}>insights</span>.
      </h3>
      <div className={classes.linkContainer}>
        <CallToActionLink href="https://www.remerge.io/newsletter">
          Subscribe to our newsletter
        </CallToActionLink>
      </div>
    </div>
  );
}

export default React.memo(Newsletter);
