import React from "react";
import classes from "./Footer.module.css";
import classnames from "classnames";
import footerIcon from "../icons/temporary-footer-illustration-1.png";
import CallToActionLink from "./CallToActionLink";

function Footer({ className }: { className?: string }) {
  return (
    <div className={classnames(classes.container, className)}>
      <div>
        <img src={footerIcon} alt="footer icon" className={classes.icon} />{" "}
      </div>
      <div className={classes.section}>
        <h4 className={classes.sectionTitle}>Any Questions?</h4>
        <CallToActionLink href="https://www.remerge.io/get-in-touch">
          Get in Touch
        </CallToActionLink>
      </div>

      <div className={classes.section}>
        <h4 className={classes.sectionTitle}>Follow us</h4>
        <a
          className={classes.socialMediaLink}
          href="https://www.facebook.com/remerge.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg width="24" height="24" fill="#000">
            <path d="M22.7 0H1.3C.6 0 0 .6 0 1.3v21.4c0 .7.6 1.3 1.3 1.3H15v-9h-3v-3.5h3V8.9c0-3 1.8-4.6 4.5-4.6 1.3 0 2.4.1 2.7.1v3.1h-1.9c-1.5 0-1.7.7-1.7 1.7v2.2h3.5l-.5 3.6h-3v9h4c.7 0 1.3-.6 1.3-1.3V1.3C24 .6 23.4 0 22.7 0z"></path>
          </svg>
        </a>
        <a
          className={classes.socialMediaLink}
          href="https://twitter.com/remergeio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg width="24" height="20" fill="#000">
            <path d="M7.5 20c9.1 0 14-7.7 14-14.4v-.7c1-.7 1.8-1.6 2.5-2.6-.9.4-1.9.7-2.8.8 1-.6 1.8-1.6 2.2-2.8-1 .6-2 1-3.1 1.2C19.6.7 18.6.2 17.5 0c-1.1-.2-2.2 0-3.1.5-1 .5-1.7 1.4-2.2 2.4-.4 1-.6 2.1-.3 3.2-2-.1-3.9-.6-5.6-1.5C4.4 3.8 2.9 2.5 1.7.9 1 2 .8 3.3 1.1 4.6c.3 1.3 1 2.3 2.1 3.1-.8-.1-1.6-.3-2.2-.7v.1c0 1.2.4 2.3 1.1 3.2s1.7 1.5 2.8 1.8c-.7.2-1.5.2-2.2.1.3 1 .9 1.9 1.8 2.5.8.6 1.8 1 2.8 1-1.7 1.4-3.9 2.2-6.1 2.2-.4 0-.8 0-1.2-.1C2.3 19.2 4.9 20 7.5 20"></path>
          </svg>
        </a>
        <a
          className={classes.socialMediaLink}
          href="https://www.instagram.com/remergelife/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg width="24" height="24" fill="#000">
            <path d="M12 0C8.7 0 8.3 0 7.1.1c-1 0-2 .2-2.9.6C3.3.9 2.6 1.4 2 2 1.4 2.6.9 3.3.6 4.1c-.3 1-.5 2-.5 3C0 8.3 0 8.7 0 12c0 3.3 0 3.7.1 4.9 0 1 .2 2 .6 2.9.2.9.7 1.6 1.3 2.2.6.6 1.3 1.1 2.1 1.4.9.3 1.9.5 2.9.6h5c3.3 0 3.7 0 4.9-.1 1 0 2-.2 2.9-.6.8-.3 1.5-.8 2.1-1.4.6-.6 1.1-1.3 1.4-2.1.3-.9.5-1.9.6-2.9.1-1.2.1-1.6.1-4.9 0-3.3 0-3.7-.1-4.9 0-1-.2-2-.6-2.9-.2-.9-.7-1.6-1.3-2.2-.6-.6-1.3-1.1-2.1-1.4C19 .3 18 .1 17 0h-5zm0 2.2c3.2 0 3.6 0 4.8.1.8 0 1.5.1 2.2.4.5.2 1 .5 1.4.9.4.4.7.9.9 1.4.3.7.4 1.5.4 2.2.1 1.3.1 1.6.1 4.8 0 3.2 0 3.6-.1 4.8 0 .8-.1 1.5-.4 2.2-.2.5-.5 1-.9 1.4-.4.4-.9.7-1.4.9-.7.3-1.5.4-2.2.4-1.3.1-1.6.1-4.8.1s-3.6 0-4.8-.1c-.8 0-1.5-.1-2.2-.4-.5-.2-1-.5-1.4-.9-.4-.4-.7-.9-.9-1.4-.3-.7-.4-1.5-.4-2.2-.1-1.3-.1-1.7-.1-4.9s0-3.6.1-4.8c0-.8.1-1.5.4-2.2.2-.5.5-1 .9-1.4.4-.4.9-.7 1.4-.9.7-.3 1.5-.4 2.2-.4H12z"></path>
            <path d="M12 16c-.8 0-1.6-.2-2.2-.7-.7-.4-1.2-1.1-1.5-1.8-.3-.7-.4-1.5-.2-2.3.2-.8.5-1.5 1.1-2 .6-.6 1.3-.9 2-1.1.8-.2 1.6-.1 2.3.2.7.3 1.4.8 1.8 1.5.4.7.7 1.4.7 2.2 0 1.1-.4 2.1-1.2 2.8-.7.8-1.7 1.2-2.8 1.2zm0-10.2c-1.2 0-2.4.4-3.4 1-1 .7-1.8 1.6-2.3 2.8-.5 1.2-.6 2.4-.3 3.6.2 1.2.8 2.3 1.7 3.2.9.9 2 1.4 3.2 1.7 1.2.2 2.4.1 3.6-.4 1.1-.5 2.1-1.3 2.8-2.3.7-1 1-2.2 1-3.4 0-1.6-.6-3.2-1.8-4.4-1.3-1.1-2.9-1.8-4.5-1.8zM19.8 5.6c0 .3-.1.6-.2.8-.2.2-.4.4-.6.5-.3.1-.6.2-.9.1s-.5-.2-.7-.4c-.2-.2-.4-.4-.4-.7-.1-.3 0-.6.1-.8.1-.3.3-.5.5-.6.2-.2.5-.2.8-.2.2 0 .4 0 .6.1.2.1.3.2.5.3.1.1.2.3.3.5v.4z"></path>
          </svg>
        </a>
        <a
          className={classes.socialMediaLink}
          href="https://www.linkedin.com/company/remerge---app-retargeting"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg width="24" height="23" fill="#000">
            <circle cx="2.5" cy="2.5" r="2.5"></circle>
            <path d="M0 7h5v16H0zM13 7H8v16h5v-8.4c0-4.7 6-5.1 6 0V23h5V12.9c0-7.9-8.9-7.6-11-3.7V7z"></path>
          </svg>
        </a>
      </div>
    </div>
  );
}

export default React.memo(Footer);
