export default function mouseEventToDomPoint(
  event: React.MouseEvent<SVGElement, MouseEvent>,
  svg: SVGSVGElement
): { x: number; y: number } {
  const pt = svg.createSVGPoint();
  pt.x = event.clientX;
  pt.y = event.clientY;
  const { x, y } = pt.matrixTransform(svg.getScreenCTM()!.inverse());
  return { x, y };
}
