import React, { useMemo, useState } from "react";
import classes from "./App.module.css";
import { VersionHistory } from "./components/VersionHistory";
import RemergeLogo from "./icons/logo-white.svg";
import classnames from "classnames";

import {
  HistoryByVersion,
  VersionHistoryByCountry,
  BidRequestHistoryByCountry,
  SkanAdoptionByCountry,
  WeeklyCpmByCountry,
} from "./api/dataTypes";
import IdRateMap from "./components/IdRateMap";
import LineChart from "./components/LineChart";
import { max, quantile } from "d3-array";
import formatNumber from "./common/formatNumber";
import { black, colorScaleForMajorVersions, purple } from "./common/color";
import Legend from "./components/Legend";
import { compareMajorVersions } from "./common/versions";
import ChartHeading from "./components/ChartHeading";
import Selector from "./components/Selector";
import { useCountryData } from "./common/countryData";
import Newsletter from "./components/Newsletter";
import Footer from "./components/Footer";
import CpmBreakdown from "./components/CpmBreakdown";
import IntroText from "./components/IntroText";
import InfoIcon from "./components/InfoIcon";
import useIntroState from "./common/useIntroState";
import formatPercent from "./common/formatPercent";
import Partners from "./components/Partners";
import currentIdRateByCountry from "./common/currentIdRateByCountry";
import { Formatter, Series } from "./components/charts/types";
import formatShortRange, { add6days } from "./common/formatShortRange";
import { oneWeekBefore, weekRangeForDate } from "./common/weekRangeForDate";

function shouldDisplayVersion(version: string): boolean {
  return true;
}

type AppProps = {
  versionHistory: VersionHistoryByCountry;
  noIdCpm: WeeklyCpmByCountry;
  bidRequestHistory: BidRequestHistoryByCountry;
  skanAdoption: SkanAdoptionByCountry;
};

const NoIDLabel = "No-ID";
const IDLabel = "ID";

function App({
  versionHistory,
  noIdCpm,
  bidRequestHistory,
  skanAdoption,
}: AppProps) {
  const { showIntro, openIntro, closeIntro } = useIntroState();
  const [selectedCountry, setSelectedCountry] = useState("all");
  const { countries, countryNamesByCode } = useCountryData();

  const bidRequestHistorySeries = useMemo(() => {
    const items =
      bidRequestHistory[selectedCountry] ?? bidRequestHistory["all"];
    return [
      {
        label: IDLabel,
        color: black,
        values: items.map((item) => ({
          x: new Date(item.timestamp),
          y: item.id_qps,
        })),
      },
      {
        label: NoIDLabel,
        color: purple,
        values: items.map((item) => ({
          x: new Date(item.timestamp),
          y: item.no_id_qps,
        })),
      },
    ];
  }, [bidRequestHistory, selectedCountry]);

  const skanHistorySeries = useMemo(() => {
    const items = skanAdoption[selectedCountry] ?? skanAdoption["all"];
    return [
      {
        label: "SKAdNetwork Adoption",
        color: purple,
        values: items.map((item) => ({
          x: new Date(item.timestamp),
          y: item.adoption_rate,
        })),
      },
    ];
  }, [selectedCountry, skanAdoption]);
  const maxSkanAdoption = useMemo(() => {
    if (skanAdoption === null) {
      return undefined;
    }

    return quantile(
      Object.values(skanAdoption)
        .map((rows) => max(rows.map((row) => row.adoption_rate))!)
        .sort(),
      0.9
    );
  }, [skanAdoption]);

  const versionHistorySeries = useMemo(() => {
    return historyByVersionToSeries(
      versionHistory[selectedCountry] ?? versionHistory["all"]
    );
  }, [selectedCountry, versionHistory]);

  const currentIdRateByCountryData = useMemo(() => {
    return currentIdRateByCountry(bidRequestHistory);
  }, [bidRequestHistory]);

  const selectedCountryName =
    selectedCountry === "all"
      ? "All Countries"
      : countryNamesByCode[selectedCountry];

  const now = new Date();
  const currentWeek = weekRangeForDate(now);
  const lastWeek = weekRangeForDate(oneWeekBefore(now));

  return (
    <div>
      <div className={classnames(classes.cutoff, classes.left)}></div>
      <div className={classnames(classes.cutoff, classes.right)}></div>
      <div className={classes.gridContainer}>
        <div className={classes.header}>
          <h2 className={classes.pageTitle}>ID Or No ID?</h2>
          <div className={classes.pageDescriptionContainer}>
            <p className={classes.pageDescription}>
              <InfoIcon onClick={openIntro}>
                Tracking the post-IDFA changes in the world of programmatic
                mobile advertising on iOS
              </InfoIcon>
            </p>
          </div>
          <div
            className={classnames(classes.logoContainer, classes.alignRight)}
          >
            <a
              href="https://remerge.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes.logo}
                src={RemergeLogo}
                alt="Remerge logo"
              />
            </a>
          </div>
          <div
            className={classnames(classes.countrySelector, classes.alignRight)}
          >
            <Selector
              value={selectedCountry}
              onChange={(country) => setSelectedCountry(country)}
              options={[
                { label: "All Countries", value: "all" },
                ...Object.keys(currentIdRateByCountryData)
                  .map((iso3Name) => ({
                    label: countryNamesByCode[iso3Name],
                    value: iso3Name,
                  }))
                  .filter((o) => o.label)
                  .sort((a, b) => a.label.localeCompare(b.label)),
              ]}
            />
          </div>
        </div>
        <div className={classes.idRateMap}>
          <IdRateMap
            selectedCountryIso3={selectedCountry}
            countries={countries}
            cpmData={noIdCpm.current_week}
            idRateData={currentIdRateByCountryData}
            onCountrySelect={setSelectedCountry}
          >
            <IntroText shown={showIntro} onClose={closeIntro} />
          </IdRateMap>
        </div>
        <div
          className={classnames(
            classes.bidRequestHistory,
            classes.spacingTop,
            classes.left
          )}
        ></div>
        <div
          className={classnames(
            classes.bidRequestHistory,
            classes.sectionTitle,
            classes.left
          )}
        >
          <h5 className={classes.countryContextHeading}>
            {selectedCountryName}
          </h5>
          <ChartHeading
            heading={
              <h3 className={classes.heading}>
                iOS Bid Requests with ID vs. No-ID
              </h3>
            }
            legend={<Legend series={bidRequestHistorySeries} />}
          />
        </div>
        <div
          className={classnames(
            classes.bidRequestHistory,
            classes.sectionNumbers,
            classes.left
          )}
        >
          <LineChart
            yAxisLabel="REQUESTS PER SECOND"
            formatter={formatNumber}
            series={bidRequestHistorySeries}
            tooltipRenderer={noIDBidRequestsTooltipRenderer}
          />
        </div>
        <div
          className={classnames(
            classes.bidRequestHistory,
            classes.sectionDescription,
            classes.left
          )}
        >
          <p className={classes.text}>
            ID bid requests include users on iOS 13 and lower, as well as iOS 14
            users who have opt-in tracking. No-ID bid requests include users on
            iOS 14 who chose to opt out of tracking.
          </p>
        </div>
        <div
          className={classnames(
            classes.bidRequestHistory,
            classes.spacingBottom
          )}
        ></div>

        <div
          className={classnames(classes.versionHistory, classes.spacingTop)}
        ></div>
        <div
          className={classnames(
            classes.versionHistory,
            classes.sectionTitle,
            classes.left
          )}
        >
          <h5 className={classes.countryContextHeading}>
            {selectedCountryName}
          </h5>

          <ChartHeading
            heading={<h3 className={classes.heading}>iOS Version Usage</h3>}
            legend={<Legend series={versionHistorySeries} />}
          />
        </div>
        <div
          className={classnames(
            classes.versionHistory,
            classes.sectionNumbers,
            classes.left
          )}
        >
          <VersionHistory series={versionHistorySeries} />
        </div>
        <div
          className={classnames(
            classes.versionHistory,
            classes.sectionDescription,
            classes.left
          )}
        >
          <p className={classes.text}>
            This graph shows the development in iOS version usage over time. The
            ratio between ID-enabled and no-ID users will increase upon the
            release of iOS 14.5.
          </p>
        </div>
        <div
          className={classnames(classes.versionHistory, classes.spacingBottom)}
        ></div>

        <div
          className={classnames(classes.cpmBreakdown, classes.spacingTop)}
        ></div>
        <div className={classnames(classes.cpmBreakdown, classes.sectionTitle)}>
          <h5 className={classes.countryContextHeading}>
            {selectedCountryName}
          </h5>
          <h3 className={classes.heading}>Relative CPM of No-ID iOS Traffic</h3>
        </div>
        <div
          className={classnames(classes.cpmBreakdown, classes.sectionNumbers)}
        >
          <CpmBreakdown
            currentNoIdCpm={noIdCpm.current_week[selectedCountry]}
            lastWeeksNoIdCpm={noIdCpm.last_week[selectedCountry]}
            currentWeekRange={currentWeek}
            lastWeekRange={lastWeek}
          />
        </div>
        <div
          className={classnames(
            classes.cpmBreakdown,
            classes.sectionDescription
          )}
        >
          <p className={classes.text}>
            Targeting no-ID users tends to be cheaper than targeting ID-enabled
            users as the ads that are served to the former group are less
            personalized to their behavior.
          </p>
        </div>
        <div
          className={classnames(classes.cpmBreakdown, classes.spacingBottom)}
        ></div>

        <div
          className={classnames(classes.skanHistory, classes.spacingTop)}
        ></div>
        <div className={classnames(classes.skanHistory, classes.sectionTitle)}>
          <h5 className={classes.countryContextHeading}>
            {selectedCountryName}
          </h5>
          <h3 className={classes.heading}>SKAdNetwork-Enabled Bid Requests</h3>
        </div>
        <div
          className={classnames(classes.skanHistory, classes.sectionNumbers)}
        >
          <LineChart
            formatter={formatPercent}
            series={skanHistorySeries}
            maxValueGuideLine={maxSkanAdoption}
          />
        </div>
        <div
          className={classnames(
            classes.skanHistory,
            classes.sectionDescription
          )}
        >
          <p className={classes.text}>
            SKAdNetwork is Apple’s attribution solution for no-ID advertising
            campaigns.
          </p>
        </div>
        <div
          className={classnames(classes.skanHistory, classes.spacingBottom)}
        ></div>
        <Partners className={classes.partners} />
        <Newsletter className={classes.newsletter} />
        <Footer className={classes.footer} />
        <div className={classes.imprint}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.remerge.io/terms-and-conditions"
          >
            Terms and conditions
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.remerge.io/website-privacy-policy"
          >
            Website privacy policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.remerge.io/service-privacy-policy"
          >
            Service privacy policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.remerge.io/opt-out-of-ads"
          >
            Opt out of ads
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.remerge.io/imprint"
          >
            Imprint
          </a>
        </div>
      </div>
    </div>
  );
}

export function historyByVersionToSeries(
  byVersion: HistoryByVersion
): Series[] {
  const colorScale = colorScaleForMajorVersions(
    Object.keys(byVersion).sort(compareMajorVersions)
  );
  return Object.entries(byVersion)
    .filter(([version]) => shouldDisplayVersion(version))
    .filter(([version, metrics]) => !!metrics)
    .sort(([v1], [v2]) => {
      if (v1 === "others") {
        return 1;
      }

      if (v2 === "others") {
        return -1;
      }
      return v1 > v2 ? -1 : 1;
    })
    .map(([version, metrics]) => ({
      label: version,
      color: colorScale(version),
      values: metrics.map((metric) => ({
        x: new Date(metric.timestamp),
        y: metric.user_portion,
      })),
    }));
}

function noIDBidRequestsTooltipRenderer(
  time: Date,
  tooltipItems: {
    [seriesLabel: string]: number;
  },
  valueFormatter: Formatter
) {
  const noIDCount = tooltipItems[NoIDLabel];
  const iDCount = tooltipItems[IDLabel];
  const total = noIDCount + iDCount;
  const noIdRate = noIDCount / total;
  const idRate = iDCount / total;

  return (
    <>
      <h4>{formatShortRange(time, add6days(time))}</h4>
      <table>
        <thead>
          <tr>
            <th className={classes.bidRequestLegendHeader}>{IDLabel}</th>
            <th className={classes.bidRequestLegendHeader}>{NoIDLabel}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.bidRequestLegendPadRight}>
              {valueFormatter(iDCount)}
            </td>
            <td>{valueFormatter(noIDCount)}</td>
          </tr>
          <tr>
            <td className={classes.bidRequestLegendPadRight}>
              ({formatPercent(idRate)})
            </td>
            <td>({formatPercent(noIdRate)})</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default App;
