export default function formatNumber(v: number): string {
  let unit = "";
  let unitAdjustedNumber = v;
  if (v / 10 ** 6 >= 1) {
    unitAdjustedNumber = v / 10 ** 6;
    unit = "mil";
  } else if (v / 10 ** 3 >= 10) {
    unitAdjustedNumber = v / 10 ** 3;
    unit = "k";
  }

  return `${unitAdjustedNumber.toPrecision(
    precisionForWholeNumber(unitAdjustedNumber, 2)
  )}${unit}`;
}

function precisionForWholeNumber(v: number, minimumPrecision: number): number {
  const exponents = Math.floor(Math.log10(v)) + 1;
  if (exponents > minimumPrecision) {
    return exponents;
  }

  return minimumPrecision;
}
