export function uniqueMajorVersions(versions: string[]): string[] {
  return Object.keys(
    versions
      .map((v) => majorVersion(v))
      .reduce(
        (acc, majorVersion) => ({
          ...acc,
          [majorVersion]: true,
        }),
        {} as { [version: string]: boolean }
      )
  ).sort(compareMajorVersions);
}

export function compareMajorVersions(a: string, b: string) {
  if (a === "others") {
    return -1;
  }
  if (b === "others") {
    return 1;
  }
  if (a < b) {
    return -1;
  }

  return 1;
}

export function majorVersion(versionName: string): string {
  const versionSplit = versionName.split(".");
  return versionSplit[0];
}
