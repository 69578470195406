import {
  bidRequestsByCountry,
  BidRequestHistoryByCountry,
} from "../api/dataTypes";

export default function currentIdRateByCountry(
  idRateByCountry: BidRequestHistoryByCountry
): bidRequestsByCountry {
  return Object.entries(idRateByCountry).reduce(
    (acc, [country, historyRows]) => {
      const lastRow = historyRows[historyRows.length - 1];

      return { ...acc, [country]: lastRow };
    },
    {} as bidRequestsByCountry
  );
}
