import React from "react";
import classes from "./InfoIcon.module.css";

export default function InfoIcon(
  props: React.PropsWithChildren<{ onClick: () => void }>
) {
  return (
    <span className={classes.clickContainer} onClick={props.onClick}>
      {props.children} <Icon />
    </span>
  );
}

function Icon(props: {}) {
  return (
    <svg
      className={classes.icon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="black" />
      <path
        d="M6.56208 8.23338H7.32957V7.96744C7.32957 7.51153 7.42889 7.27408 7.69977 7.00814L8.15124 6.56174C8.57562 6.14383 9 5.57395 9 4.89959C9 3.68385 8.24153 3 7.01354 3C5.6772 3 5 3.83582 5 4.99457C5 5.09905 5 5.26052 5.04515 5.50746L5.86682 5.61194C5.81264 5.346 5.81264 5.24152 5.81264 5.10855C5.81264 4.23474 6.30925 3.74084 7.01354 3.74084C7.66366 3.74084 8.14221 4.19674 8.14221 4.89959C8.14221 5.49796 7.82618 5.87788 7.51919 6.18182L7.07675 6.61872C6.58014 7.10312 6.56208 7.52103 6.56208 7.83446V8.23338ZM6.48081 10H7.42889V8.99322H6.48081V10Z"
        fill="#EDFF53"
      />
    </svg>
  );
}
