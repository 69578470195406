import React, { useCallback, useRef, useMemo } from "react";
import { ScaleLinear, scaleLinear, ScaleTime, scaleTime } from "d3-scale";
import { max, min } from "d3-array";
import { line, curveCardinal } from "d3-shape";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import Tooltip from "./Tooltip";
import formatPercent from "../common/formatPercent";
import chartClasses from "./charts/chart.module.css";

import {
  Formatter,
  Item,
  Series,
  TooltipRenderer,
  TooltipLocation,
} from "./charts/types";
import Axes from "./charts/Axes";
import { height, margin, width } from "./charts/dimensions";
import { nearestItemTo } from "./charts/helpers";
import formatShortRange, { add6days } from "../common/formatShortRange";

export type LineChartProps = {
  series: Series[];
  maxValueGuideLine?: number;
  formatter?: Formatter;
  yAxisLabel?: string;
  tooltipRenderer?: TooltipRenderer;
};

function LineChart({
  series,
  maxValueGuideLine,
  yAxisLabel,
  formatter = formatPercent,
  tooltipRenderer = defaultTooltipRenderer,
}: LineChartProps) {
  const { maxY, minX, maxX, x, y } = useMemo(() => {
    let maxY = max(allValues(series, "y"))!;
    if (maxValueGuideLine && maxValueGuideLine > maxY) {
      maxY = maxValueGuideLine;
    }

    const minX = min(allValues(series, "x"))!;
    const maxX = max(allValues(series, "x"))!;

    const x = scaleTime().domain([minX, maxX]).range([0, width]);
    const y = scaleLinear().domain([0, maxY]).range([height, 0]);

    return { maxY, minX, maxX, x, y };
  }, [maxValueGuideLine, series]);
  const svgRef = useRef<SVGSVGElement>(null);
  const [tooltip, setTooltip] = React.useState(null as TooltipLocation | null);

  const showTooltip = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      const svg = svgRef.current;
      if (!svg) {
        return;
      }
      const { x, y } = mouseEventToDomPoint(event, svg);
      setTooltip({
        svgX: x,
        svgY: y,
        mouseX: event.pageX,
        mouseY: event.pageY,
      });
    },
    []
  );

  const hideTooltip = useCallback(() => {
    setTooltip(null);
  }, []);

  const closestTimeToMouse =
    tooltip &&
    series.length > 0 &&
    nearestItemTo(x.invert(tooltip.svgX - margin.left), series[0].values).x;

  const closestValueToMousePerSeries =
    tooltip &&
    series.length > 0 &&
    series.reduce(
      (acc, current) => ({
        ...acc,
        [current.label]: nearestItemTo(
          x.invert(tooltip.svgX - margin.left),
          current.values
        ).y,
      }),
      {} as { [title: string]: number }
    );

  return (
    <div>
      {tooltip && closestTimeToMouse && (
        <Tooltip
          side={tooltip.svgX > width / 2 ? "left" : "right"}
          pageX={tooltip.mouseX}
          pageY={tooltip.mouseY}
        >
          {closestValueToMousePerSeries &&
            tooltipRenderer(
              closestTimeToMouse,
              closestValueToMousePerSeries,
              formatter
            )}
        </Tooltip>
      )}
      <svg
        className="svg"
        viewBox={`0 0 ${width + margin.left + margin.right} ${
          height + margin.bottom + margin.top
        }`}
        ref={svgRef}
        onMouseMove={showTooltip}
        onClick={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <g transform={`translate(${margin.left},${margin.top})`}>
          {series.length > 0 && (
            <Axes
              {...{
                x,
                y,
                minX,
                maxX,
                max,
                maxY,
                formatter,
                yAxisLabel,
                renderYTickGuidelines: true,
              }}
            />
          )}
          {series.map((s) => (
            <Graph key={s.label} x={x} y={y} series={s} />
          ))}

          {closestTimeToMouse && (
            <line
              style={{ transform: `translate(${x(closestTimeToMouse)}px)` }}
              className={chartClasses.tooltipHighlightLine}
              x1={0}
              x2={0}
              y1={y(0)!}
              y2={y(maxY)}
            />
          )}

          {closestValueToMousePerSeries &&
            Object.entries(closestValueToMousePerSeries).map(
              ([title, value]) => (
                <line
                  key={title}
                  style={{
                    transform: `translate(0, ${y(value)}px)`,
                  }}
                  className={chartClasses.tooltipHighlightLine}
                  x1={0}
                  x2={x(maxX)}
                  y1={0}
                  y2={0}
                />
              )
            )}
        </g>
      </svg>
    </div>
  );
}

const Graph = React.memo(
  ({
    x,
    y,
    series,
    pathProps,
  }: {
    series: Series;
    x: ScaleTime<number, number>;
    y: ScaleLinear<number, number>;
    pathProps?: React.SVGProps<SVGPathElement>;
  }) => {
    const a = line<{ x: number; y: number }>()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(curveCardinal);

    const values = series.values.map((i) => ({
      x: x(i.x)!,
      y: y(i.y)!,
    }));

    return (
      <path
        className={chartClasses.graph}
        stroke={series.color}
        d={a(values)!}
        {...pathProps}
      />
    );
  }
);

function allValues<Key extends keyof Item>(
  series: Series[],
  k: Key
): Item[Key][] {
  return series.flatMap((s) => s.values.map((i) => i[k]));
}

const defaultTooltipRenderer = (
  time: Date,
  tooltipItems: { [seriesLabel: string]: number },
  valueFormatter: Formatter
) => {
  return (
    <>
      <h4>{formatShortRange(time, add6days(time))}</h4>
      {Object.entries(tooltipItems).map(([title, value]) => (
        <p key={title}>
          {title}: {valueFormatter(value)}
        </p>
      ))}
    </>
  );
};

export default React.memo(LineChart);
