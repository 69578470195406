import { WeekRange } from "../api/dataTypes";

export function weekRangeForDate(date: Date): WeekRange {
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );

  const beginningOfWeek = new Date(
    Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate() - moveToMondayFirstIndex(utcDate.getDay())
    )
  );

  const endOfWeek = new Date(
    Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate() + (6 - moveToMondayFirstIndex(utcDate.getDay()))
    )
  );

  return {
    from: beginningOfWeek.toISOString(),
    to: endOfWeek.toISOString(),
  };
}

export function oneWeekBefore(date: Date): Date {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - 7)
  );
}

function moveToMondayFirstIndex(dayIndex: number): number {
  if (dayIndex === 0) {
    return 6;
  }

  return dayIndex - 1;
}
