import React from "react";
import classnames from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Partners.css";
import classes from "./Partners.module.css";
import { Carousel } from "react-responsive-carousel";
import partners from "../partner_logos/partners";
import { isPortraitMobile } from "../common/useIntroState";

export default function Partners(props: { className?: string }) {
  const isMobile = isPortraitMobile();
  const maxRowLength = isMobile ? 3 : 6;
  return (
    <div className={classnames(classes.container, props.className)}>
      <h3 className={classes.title}>Methodology</h3>
      <p className={classes.description}>
        The post-IDFA dashboard insights are fuelled by the data we receive from
        our trusted industry partners. We work with 26 supply side platforms to
        give our clients access to hundreds of millions of users on iOS and
        Android devices. Our bidding infrastructure is built to manage these
        large amounts of traffic and processes 3.3 million queries per second,
        more than any other demand side platform in the market.
      </p>
      <div className={classes.sliderWraper}>
        <Carousel
          autoPlay
          interval={5000}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          emulateTouch
          infiniteLoop
          stopOnHover
        >
          {rowsOfN(maxRowLength, partners).map((row, i) => (
            <div
              className={classnames(classes.imgRow, {
                [classes.mobile]: isMobile,
                [classes.alignStart]: row.length < maxRowLength,
              })}
              key={i}
            >
              {row.map((partner, i) => (
                <div
                  key={partner}
                  style={{ backgroundImage: `url(${partner})` }}
                  className={classes.img}
                />
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

function rowsOfN(itemsPerRow: number, images: string[]): string[][] {
  const rows: string[][] = [];

  let currentRow: string[] = [];

  images.forEach((image) => {
    currentRow.push(image);
    if (currentRow.length >= itemsPerRow) {
      rows.push(currentRow);
      currentRow = [];
    }
  });
  if (currentRow.length > 0) {
    rows.push(currentRow);
  }

  return rows;
}
