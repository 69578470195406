import React from "react";
import classes from "./Tooltip.module.css";
import classnames from "classnames";

type Props = {
  pageX: number;
  pageY: number;
  side: "left" | "right";
};

const yOffset = -12;

export default function Tooltip(props: React.PropsWithChildren<Props>) {
  if (props.side === "right") {
    return (
      <div
        style={{ left: props.pageX + 20, top: props.pageY + yOffset }}
        className={classnames(classes.container, classes.right)}
      >
        {props.children}
      </div>
    );
  }

  return (
    <div
      style={{
        right: `calc(100% - ${props.pageX - 10}px)`,
        top: props.pageY + yOffset,
      }}
      className={classnames(classes.container, classes.left)}
    >
      {props.children}
    </div>
  );
}
