import React from "react";
import { ScaleLinear, ScaleTime } from "d3-scale";
import { Formatter } from "./types";
import classes from "./Axes.module.css";
import YAxis from "./YAxis";
import XAxis from "./XAxis";
import classnames from "classnames";

type Props = {
  x: ScaleTime<number, number>;
  y: ScaleLinear<number, number>;
  minX: Date;
  maxX: Date;
  maxY: number;
  formatter: Formatter;
  yAxisLabel?: string;
  renderYTickGuidelines?: boolean;
};

function Axes({
  x,
  y,
  minX,
  maxX,
  maxY,
  formatter,
  yAxisLabel,
  renderYTickGuidelines,
}: Props) {
  return (
    <g>
      <line
        key={`yAxisLine`}
        x1={x(minX)}
        y1={y(maxY)}
        x2={x(minX)}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <line
        key={`xAxisLine`}
        x1={x(minX)}
        y1={y(0)}
        x2={x(maxX)}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <YAxis
        y={y}
        formatter={formatter}
        maxSvgX={x(maxX)!}
        renderGuideLines={renderYTickGuidelines}
      />
      <g transform={`translate(${0},${y(0)!})`}>
        <XAxis x={x} />
      </g>
      <g transform={`translate(${x(minX)! - 80},${y(maxY / 3)}) rotate(-90)`}>
        <text x={0} y={0}>
          {yAxisLabel}
        </text>
      </g>
    </g>
  );
}

export default React.memo(Axes);
