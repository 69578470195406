import React, { useMemo, useRef } from "react";
import { ScaleLinear, scaleLinear, ScaleTime, scaleTime } from "d3-scale";
import { max, min } from "d3-array";
import { stack, area, curveCardinal } from "d3-shape";
import Tooltip from "./Tooltip";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import formatPercent from "../common/formatPercent";
import { Item, Series, TooltipLocation } from "./charts/types";
import Axes from "./charts/Axes";
import { height, margin, width } from "./charts/dimensions";
import { nearestItemTo } from "./charts/helpers";
import chartClasses from "./charts/chart.module.css";
import formatShortRange, { add6days } from "../common/formatShortRange";

type ChartItem = {
  x: number;
  y: number;
  y1: number;
};

export type VersionHistoryProps = {
  series: Series[];
};

type SelectedVersion = TooltipLocation & {
  name: string;
};

export function VersionHistory({
  series,
}: React.PropsWithChildren<VersionHistoryProps>) {
  const [tooltip, setTooltip] = React.useState(null as SelectedVersion | null);

  const { x, y, minX, maxX, maxY } = useMemo(() => {
    const allValues = ([] as Item[]).concat(...series.map((s) => s.values));
    const maxY = 1;
    const minX = min(allValues.map((v) => v.x))!;
    const maxX = max(allValues.map((v) => v.x))!;
    const x = scaleTime().domain([minX, maxX]).range([0, width]);

    const y = scaleLinear().domain([0, maxY]).range([height, 0]);

    return { minX, maxY, maxX, x, y };
  }, [series]);

  const svgRef = useRef<SVGSVGElement>(null);

  const selectedSeries = series.find((s) => s.label === tooltip?.name) || null;
  const itemClosestToMouse =
    tooltip &&
    selectedSeries &&
    nearestItemTo(x.invert(tooltip.svgX - margin.left), selectedSeries.values);

  return (
    <div>
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${
          height + margin.bottom + margin.top
        }`}
        ref={svgRef}
      >
        <g
          transform={`translate(${margin.left},${margin.top})`}
          width={width + margin.right}
          height={height}
        >
          <g className="series">
            <SeriesGraphs
              {...{
                series,
                x,
                y,
                svgRef,
                displayTooltip: setTooltip,
              }}
            />
          </g>
          <Axes
            {...{
              x,
              y,
              minX,
              maxX,
              max,
              maxY,
              formatter: formatPercent,
            }}
          />
          {itemClosestToMouse && (
            <line
              style={{ transform: `translate(${x(itemClosestToMouse.x)}px)` }}
              className={chartClasses.tooltipHighlightLine}
              x1={0}
              x2={0}
              y1={y(0)!}
              y2={y(maxY)}
            />
          )}
        </g>
      </svg>
      {tooltip && (
        <Tooltip
          side={tooltip.svgX > width / 2 ? "left" : "right"}
          pageX={tooltip.mouseX}
          pageY={tooltip.mouseY}
        >
          {tooltip.name !== "others" ? (
            <h3>iOS {tooltip.name}</h3>
          ) : (
            <h3>Other iOS versions</h3>
          )}
          <p>
            {itemClosestToMouse &&
              formatShortRange(
                itemClosestToMouse.x,
                add6days(itemClosestToMouse.x)
              ) + ": "}
            {formatPercent(itemClosestToMouse?.y || 0)}
          </p>
        </Tooltip>
      )}
    </div>
  );
}

const SeriesGraphs = React.memo(
  ({
    x,
    y,
    svgRef,
    series,
    displayTooltip,
  }: React.PropsWithChildren<{
    series: Series[];
    svgRef: React.RefObject<SVGSVGElement>;
    x: ScaleTime<number, number>;
    y: ScaleLinear<number, number>;
    displayTooltip: (version: SelectedVersion | null) => void;
  }>) => {
    const byUnixDate: {
      [ts: string]: { [version: string]: number };
    } = {};
    const labelToColor: { [label: string]: string } = {};
    series.forEach((s) => {
      labelToColor[s.label] = s.color;
      s.values.forEach((v) => {
        const ts = v.x.valueOf().toString();
        if (!byUnixDate[ts]) {
          byUnixDate[ts] = {};
        }

        byUnixDate[ts][s.label] = v.y;
      });
    });

    const objectPerDate: ({ [version: string]: number } & {
      ts: number;
    })[] = Object.entries(byUnixDate)
      .map(([ts, versionToRatio]) => ({
        ts: Number(ts),
        ...versionToRatio,
      }))
      .sort((a, b) => a.ts - b.ts);

    const stackGenerator = stack().keys(series.map((s) => s.label));
    const stackedData = stackGenerator(objectPerDate);
    return (
      <>
        {stackedData.map((s, index) => {
          const transformedValues = s
            .map((p) => {
              const date = new Date(p.data.ts);

              const xtoDraw = x(date)!;
              const y0ToDraw = y(p[0])!;
              const y1ToDraw = y(p[1])!;

              return {
                x: xtoDraw,
                y: y0ToDraw,
                y1: y1ToDraw,
              };
            })
            .filter((d) => d.x !== undefined && d.y !== undefined);

          const showTooltip = (
            event: React.MouseEvent<SVGPathElement, MouseEvent>
          ) => {
            const svg = svgRef.current;
            if (!svg) {
              return;
            }
            const { x, y } = mouseEventToDomPoint(event, svg);
            displayTooltip({
              name: s.key,
              svgX: x,
              svgY: y,
              mouseX: event.pageX,
              mouseY: event.pageY,
            });
          };
          const hideTooltip = () => {
            displayTooltip(null);
          };

          const seriesColor = labelToColor[s.key];
          const fill = seriesColor;
          const stroke = "black";

          return path(transformedValues, {
            key: s.key,
            style: { fill, stroke },
            onMouseMove: showTooltip,
            onClick: showTooltip,
            onMouseLeave: hideTooltip,
          });
        })}
      </>
    );
  }
);

function path(values: ChartItem[], pathProps: React.SVGProps<SVGPathElement>) {
  const a = area<ChartItem>()
    .x((d) => d.x)
    .y0((d) => d.y)
    .y1((d) => d.y1)
    .curve(curveCardinal.tension(0.6));

  return <path d={a(values)!} {...pathProps} />;
}
