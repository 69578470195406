import { scaleLinear, scaleOrdinal } from "d3-scale";
import { interpolateRgb } from "d3-interpolate";

export const black = "#1D1D1F";
export const white = "#fff";
export const yellow = "#edff53";
export const green = "#54665a";
export const bronze = "#bfbda7";
export const purple = "#d2c8ff";
export const lightblue = "#e0e4ef";
export const lightgrey = "#f6f4f2";
export const offWhite = "#FEFEFF";
export const purpleShade = "#E6E1FF";
export const sand = "#EFEDDB";
export const beige = "#DAD8CC";

export function colorForIdRate(rate: number, from: number, to: number) {
  const c = interpolateRgb(white, purple);
  const rateScale = scaleLinear().domain([from, to]).range([0, 1]);

  return c(rateScale(rate)!);
}

export function colorForCpm(cpm: number, from: number, to: number) {
  const c = interpolateRgb(lightgrey, bronze);
  const rateScale = scaleLinear().domain([from, to]).range([1, 0]);

  return c(rateScale(cpm)!);
}

export function colorScaleForMajorVersions(majorVersions: string[]) {
  const color = scaleOrdinal<string, string>()
    .domain(majorVersions)
    .range([beige, sand, bronze, purpleShade, purple]);

  return color;
}
