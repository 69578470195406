import React, { useEffect, useState } from "react";
import classes from "./IntroText.module.css";
import classnames from "classnames";
import CallToActionLink from "./CallToActionLink";

function IntroText(props: { shown: boolean; onClose: () => void }) {
  const [showText, setShowText] = useState(props.shown);

  useEffect(() => {
    if (!showText && props.shown) {
      setShowText(true);
      return;
    }

    if (showText && !props.shown) {
      const id = setTimeout(() => {
        setShowText(false);
      }, 1000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [showText, props.shown]);

  return (
    <div
      className={classnames(classes.container, {
        [classes.shown]: props.shown,
      })}
    >
      <CloseIcon onClick={props.onClose} />
      {showText && (
        <>
          <h3 className={classes.title}>App Marketing In The Post-IDFA Era</h3>
          <p>
            This site provides daily updates relevant to the programmatic
            advertising industry, impacted by Apple’s user privacy-centric iOS
            14.5 release.
          </p>
          <p>
            You can view and compare the figures of bid requests, CPM changes,
            iOS version usage, and SKAdNetwork-enabled traffic filtered by
            country, using our interactive map.
          </p>
          <p>
            Get the new ID or No ID? report on app marketing strategies in the
            privacy-first era and 2022 market predictions. Hear from experts at
            Remerge and ironSource.
          </p>
          <CallToActionLink href="https://www.remerge.io/app-marketing-strategies-privacy-era-2022-remerge-ironsource">
            Access the report
          </CallToActionLink>
        </>
      )}
    </div>
  );
}

export default React.memo(IntroText);

function CloseIcon(props: { onClick(): void }) {
  return (
    <svg
      className={classes.icon}
      onClick={props.onClick}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.550293L23.9497 23.5" stroke="black" />
      <path d="M23.9497 0.550293L1.00003 23.5" stroke="black" />
    </svg>
  );
}
