import React from "react";
import classes from "./CallToActionLink.module.css";

export default function CallToActionLink(
  props: React.PropsWithChildren<{ href: string; className?: string }>
) {
  return (
    <a
      className={classes.link}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
}
