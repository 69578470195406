import React from "react";
import versionHistory from "./data/versions.json";
import noIdCpm from "./data/cpm_rate.json";
import bidRequestHistory from "./data/bid_request_history.json";
import skanAdoption from "./data/skan_adoption.json";

import App from "./App";

export default function AppData() {
  return (
    <App
      {...{
        versionHistory,
        noIdCpm,
        bidRequestHistory: bidRequestHistory,
        skanAdoption,
      }}
    />
  );
}
