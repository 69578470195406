import React from "react";
import { ScaleTime } from "d3-scale";
import formatShortDate from "../../common/formatShortDate";
import { tickLength } from "./dimensions";
import axesClasses from "./Axes.module.css";

function XAxis({ x }: { x: ScaleTime<number, number> }) {
  const tickDates = x.ticks(6);
  const formater = formatShortDate;
  return (
    <>
      {tickDates.map((t, i) => (
        <g key={`tick/${t}`}>
          <line
            x1={x(t)!}
            x2={x(t)!}
            y1={0}
            y2={tickLength}
            className={axesClasses.line}
          />
          <text x={x(t)! + 2} y={tickLength + 15}>
            {formater(t)}
          </text>
        </g>
      ))}
    </>
  );
}

export default React.memo(XAxis);
