import React from "react";
import { NoIdCpmRate, WeekRange } from "../api/dataTypes";
import formatPercent from "../common/formatPercent";
import classes from "./CpmBreakdown.module.css";
import classnames from "classnames";
import formatWeekRange from "../common/formatWeekRange";

export type CpmBreakdownProps = {
  currentNoIdCpm?: NoIdCpmRate;
  lastWeeksNoIdCpm?: NoIdCpmRate;
  currentWeekRange: WeekRange;
  lastWeekRange: WeekRange;
};

function CpmBreakdown({
  currentNoIdCpm,
  lastWeeksNoIdCpm,
  currentWeekRange,
  lastWeekRange,
}: CpmBreakdownProps) {
  if (!currentNoIdCpm || !lastWeeksNoIdCpm) {
    return (
      <div className={classes.noDataContainer}>
        <p>
          We don't have enough data for this country to give you credible
          numbers about the CPM
        </p>
      </div>
    );
  }

  const costReduction = 1 - currentNoIdCpm.no_id_cpm_ratio;
  const lastWeeksReduction = 1 - lastWeeksNoIdCpm.no_id_cpm_ratio;
  return (
    <div className={classes.grid}>
      <div className={classes.borderRight}>
        <div className={classes.cpmContainerWrapper}>
          <div className={classnames(classes.cpmContainer)}>
            <p className={classes.weekLabel}>This Week</p>
            <p className={classes.weekRange}>
              {formatWeekRange(currentWeekRange)}
            </p>
            <div className={classes.outlineBackground}>
              <h1 className={classes.cpmCpm}>
                {formatPercent(Math.abs(costReduction))}
              </h1>
              <p className={classes.cpmDescription}>
                <span className="serif">
                  {costReduction >= 0 ? "cheaper" : "More expensive"} compared
                  to{" "}
                </span>
                ID-enabled <span className="serif">traffic</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.cpmContainerWrapper}>
          <div className={classes.cpmContainer}>
            <p className={classes.weekLabel}>Last Week</p>
            <p className={classes.weekRange}>
              {formatWeekRange(lastWeekRange)}
            </p>
            <div className={classes.paddingLeft}>
              <h1 className={classnames(classes.cpmCpm, classes.mutedColor)}>
                {formatPercent(Math.abs(lastWeeksReduction))}
              </h1>
              <p className={classes.cpmDescription}>
                <span className="serif">
                  {lastWeeksReduction >= 0 ? "cheaper" : "More expensive"}{" "}
                  compared to{" "}
                </span>
                ID-enabled <span className="serif">traffic</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CpmBreakdown);
