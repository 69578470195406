import React from "react";
import classes from "./ChartHeading.module.css";

export type ChartHeadingProps = {
  heading: JSX.Element;
  legend: JSX.Element;
};

function ChartHeading({
  heading: headingElement,
  legend: legendElement,
}: ChartHeadingProps) {
  return (
    <div className={classes.container}>
      <div>{headingElement}</div>
      <div>{legendElement}</div>
    </div>
  );
}

export default React.memo(ChartHeading);
