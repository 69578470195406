import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import classes from "./IdRateMap.module.css";
import { GeoPath, geoPath, GeoPermissibleObjects, GeoProjection } from "d3-geo";
import { geoRobinson } from "d3-geo-projection";
import { NoIdCpmByCountry, BidRequestHistoryRow } from "../api/dataTypes";
import { max, min, quantile } from "d3-array";
import Tooltip from "./Tooltip";
import { colorForIdRate } from "../common/color";
import formatPercent from "../common/formatPercent";
import { zoom, zoomIdentity } from "d3-zoom";
import { select } from "d3-selection";
import classnames from "classnames";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import plusIcon from "../icons/plus-icon.svg";
import minusIcon from "../icons/minus-icon.svg";
import { isPortraitMobile } from "../common/useIntroState";
import isSnapshotting from "../common/isSnapshotting";

export type IdRateMapProps = {
  countries: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  cpmData: NoIdCpmByCountry;
  idRateData: { [country: string]: BidRequestHistoryRow };
  selectedCountryIso3: string;
  onCountrySelect(countryIso3: string): void;
};

const width = 2000;
const margin = {
  top: 40,
  right: 0,
  bottom: 0,
  left: 0,
};

type CountryFeatureProps = {
  name: string;
  iso3: string;
};

type SelectedCountry = {
  iso3: string;
  name: string;
  mouseX: number;
  mouseY: number;
  svgX: number;
  svgY: number;
};

function IdRateMap({
  countries,
  cpmData,
  idRateData,
  onCountrySelect,
  children,
  selectedCountryIso3,
}: React.PropsWithChildren<IdRateMapProps>) {
  const [tooltipCountry, setTooltipCountry] = useState(
    null as null | SelectedCountry
  );

  const setSelectedCountry = useCallback(
    (v: SelectedCountry) => {
      if (idRateData[v.iso3]) {
        onCountrySelect(v.iso3);
      }
      setTooltipCountry(v);
    },
    [idRateData, onCountrySelect]
  );

  return (
    <div>
      {tooltipCountry && (
        <MapTooltip
          side={tooltipCountry.svgX > width / 2 ? "left" : "right"}
          idRateData={idRateData}
          cpmData={cpmData}
          selectedCountry={tooltipCountry}
        />
      )}

      <Map
        selectedCountryIso3={selectedCountryIso3}
        countries={countries}
        idRateData={idRateData}
        onCountryHover={setTooltipCountry}
        onCountryClick={setSelectedCountry}
      >
        {children}
      </Map>
    </div>
  );
}

function calculateHeight(projection: GeoProjection): number {
  const outline = { type: "Sphere" };
  const [[x0, y0], [x1, y1]] = geoPath(
    projection.fitWidth(width, outline as any)
  ).bounds(outline as any);
  const dy = Math.ceil(y1 - y0),
    l = Math.min(Math.ceil(x1 - x0), dy);
  projection.scale((projection.scale() * (l - 1)) / l).precision(0.2);
  return dy;
}

const Map = React.memo(
  ({
    children,
    countries,
    idRateData,
    onCountryHover,
    onCountryClick,
    selectedCountryIso3,
  }: React.PropsWithChildren<
    Pick<IdRateMapProps, "idRateData" | "countries" | "selectedCountryIso3"> & {
      onCountryHover(v: null | SelectedCountry): void;
      onCountryClick(v: SelectedCountry): void;
    }
  >) => {
    const [zoomProps, setZoomProps] = useState({
      canZoom: false,
      zoomScale: 1.464085695945626,
    });

    useEffect(() => {
      if (!zoomProps.canZoom) {
        return;
      }
      const handler = () => {
        setZoomProps((prev) => ({ ...prev, canZoom: false }));
      };
      document.addEventListener("scroll", handler);

      return () => {
        document.removeEventListener("scroll", handler);
      };
    }, [zoomProps]);

    const maxValue =
      quantile(
        Object.values(idRateData)
          .map((d) => d.no_id_portion)
          .sort(),
        0.95
      ) || 0;
    const minValue =
      quantile(
        Object.values(idRateData)
          .map((d) => d.no_id_portion)
          .sort(),
        0.1
      ) || 0;

    const projection = useMemo(() => geoRobinson(), []);
    const height = useMemo(() => calculateHeight(projection), [projection]);

    const svgRef = useRef<SVGSVGElement>(null);

    return (
      <div className={classes.mapContainer}>
        {children}
        <svg className={classes.legend} viewBox="0 0 510 70">
          <text className={classes.legendTitle} x={0} y={25}>
            No-ID Bid Requests
          </text>

          <rect
            style={{ fill: `url(#id-rate-legend-gradient)` }}
            x={260}
            y={0}
            width={200}
            height={30}
            stroke={"#000"}
          ></rect>
          {minValue !== undefined && (
            <text className={classes.legendText} x={260} y={70}>
              {formatPercent(minValue)}
            </text>
          )}
          {maxValue && (
            <text className={classes.legendText} x={410} y={70}>
              {formatPercent(maxValue)}
            </text>
          )}
        </svg>
        <div className={classes.zoomButtonsContainer}>
          <button
            className={classnames(classes.zoomButton, {
              [classes.cannotZoom]: !zoomProps.canZoom,
            })}
            onClick={() => {
              setZoomProps((prev) => ({
                canZoom: true,
                zoomScale: max([prev.zoomScale * 2, 2])!,
              }));
            }}
          >
            <img src={plusIcon} alt="plus" />
          </button>
          <button
            className={classnames(classes.zoomButton, {
              [classes.cannotZoom]: !zoomProps.canZoom,
            })}
            onClick={() => {
              setZoomProps((prev) => ({
                canZoom: true,
                zoomScale: min([prev.zoomScale / 2, 0.5])!,
              }));
            }}
          >
            <img src={minusIcon} alt="minus" />
          </button>
        </div>

        <svg
          className={classes.svg}
          ref={svgRef}
          viewBox={`0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`}
        >
          <defs>
            <linearGradient
              id={`id-rate-legend-gradient`}
              x1="0"
              x2="1"
              y1="0"
              y2="0"
            >
              <stop
                offset="5%"
                stopColor={colorForIdRate(
                  minValue || 0,
                  minValue || 0,
                  maxValue || 0
                )}
              />
              <stop
                offset="95%"
                stopColor={colorForIdRate(
                  maxValue || 0,
                  minValue || 0,
                  maxValue || 0
                )}
              />
            </linearGradient>
            <pattern
              id="diagonal-stripe-1"
              patternUnits="userSpaceOnUse"
              width="10"
              height="10"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9IjAiLz4KICA8cGF0aCBkPSdNLTEsMSBsMiwtMgogICAgICAgICAgIE0wLDEwIGwxMCwtMTAKICAgICAgICAgICBNOSwxMSBsMiwtMicgc3Ryb2tlPSdibGFjaycgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPgo="
                x="0"
                y="0"
                width="10"
                height="10"
              ></image>
            </pattern>
          </defs>
          <CountriesZoom
            {...{
              svgRef,
              height,
              countries,
              idRateData,
              projection,
              onCountryHover,
              onCountryClick,
              minValue,
              maxValue,
              zoomProps,
              selectedCountryIso3,
            }}
          />
        </svg>
      </div>
    );
  }
);

const CountriesZoom = React.memo(
  ({
    svgRef,
    height,
    zoomProps,
    onCountryHover,
    ...restProps
  }: {
    height: number;
    zoomProps: { canZoom: boolean; zoomScale: number };
  } & CountriesProps) => {
    const [zoomTransform, setZoomTransform] = useState({
      transform: "",
      strokeWidth: 1,
    });
    const firstZoomCall = useRef(true);

    useEffect(() => {
      if (!svgRef.current) {
        return;
      }

      const translateOffset = -100;
      const zoomF = zoom()
        .scaleExtent([1, 8])
        .filter((e) => {
          return zoomProps.canZoom;
        })
        .translateExtent([
          [translateOffset, translateOffset],
          [width - translateOffset, height - translateOffset],
        ])
        .on("zoom", (e) => {
          onCountryHover(null);
          if (e.transform) {
            setZoomTransform({
              transform: e.transform,
              strokeWidth: 1 / e.transform.k,
            });
          }
        });

      const selection = select(svgRef.current).call(zoomF as any);
      if (firstZoomCall.current) {
        const t = zoomIdentity
          .translate(-500, isPortraitMobile() ? -100 : 0)
          .scale(zoomProps.zoomScale);
        (selection as any).call(zoomF.transform, t);
        firstZoomCall.current = false;
      } else if (zoomProps.canZoom) {
        (selection as any)
          .transition()
          .call(zoomF.scaleBy as any, zoomProps.zoomScale);
        firstZoomCall.current = false;
      }
    }, [height, setZoomTransform, svgRef, zoomProps, onCountryHover]);

    return (
      <g
        transform={zoomTransform.transform}
        strokeWidth={zoomTransform.strokeWidth}
      >
        {!isSnapshotting() && (
          <Countries
            svgRef={svgRef}
            onCountryHover={onCountryHover}
            {...restProps}
          />
        )}
      </g>
    );
  }
);

type CountriesProps = Pick<
  IdRateMapProps,
  "idRateData" | "countries" | "selectedCountryIso3"
> & {
  onCountryHover(v: null | SelectedCountry): void;
  onCountryClick(v: SelectedCountry): void;
  projection: GeoProjection;
  minValue: number;
  maxValue: number;
  svgRef: React.RefObject<SVGSVGElement>;
};

const Countries = React.memo(
  ({ countries, projection, ...restProps }: CountriesProps) => {
    const path = useMemo(() => geoPath(projection), [projection]);

    return (
      <g transform={`translate(${margin.left},${margin.top})`}>
        {countries.features.map((feature) => {
          const countryProps = feature.properties as CountryFeatureProps;

          return (
            <Country
              key={countryProps.iso3}
              {...{ path, feature, countryProps }}
              {...restProps}
            />
          );
        })}
      </g>
    );
  }
);

function MapTooltip({
  side,
  cpmData,
  idRateData,
  selectedCountry,
}: {
  cpmData: IdRateMapProps["cpmData"];
  idRateData: IdRateMapProps["idRateData"];
  selectedCountry: SelectedCountry;
  side: "left" | "right";
}) {
  const cpmDataForSelectedCountry =
    selectedCountry && cpmData[selectedCountry.iso3];
  const idRateDataForSelectedCountry =
    selectedCountry && idRateData[selectedCountry.iso3];
  const costDifference =
    cpmDataForSelectedCountry && 1 - cpmDataForSelectedCountry.no_id_cpm_ratio;
  return (
    <Tooltip
      side={side}
      pageX={selectedCountry.mouseX}
      pageY={selectedCountry.mouseY}
    >
      <h4 className={classes.countryName}>{selectedCountry.name}</h4>
      <>
        {idRateDataForSelectedCountry && (
          <p>
            No-ID Traffic:{" "}
            {formatPercent(idRateDataForSelectedCountry.no_id_portion)}
          </p>
        )}
        {cpmDataForSelectedCountry ? (
          <p>
            No-ID CPM: {formatPercent(Math.abs(costDifference))}{" "}
            {costDifference >= 0 ? "cheaper" : "more expensive"}
          </p>
        ) : (
          <p>No-ID CPM: N/A</p>
        )}
      </>
    </Tooltip>
  );
}

const Country = React.memo(
  ({
    selectedCountryIso3,
    countryProps,
    idRateData,
    maxValue,
    minValue,
    feature,
    svgRef,
    path,
    onCountryHover,
    onCountryClick,
  }: Omit<CountriesProps, "projection" | "countries"> & {
    countryProps: CountryFeatureProps;
    feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    path: GeoPath<any, GeoPermissibleObjects>;
  }) => {
    const countryData = idRateData[countryProps.iso3] as
      | BidRequestHistoryRow
      | undefined;

    const style = countryData
      ? {
          fill: colorForIdRate(
            countryData.no_id_portion,
            minValue || 0,
            maxValue || 0
          ),
        }
      : { fill: "fff" };

    if (countryProps.name === "Antarctica") return null;

    const showTooltip = (
      event: React.MouseEvent<SVGPathElement, MouseEvent>
    ) => {
      const svg = svgRef.current;
      if (!svg) {
        return;
      }
      const { x, y } = mouseEventToDomPoint(event, svg);

      onCountryHover({
        mouseX: event.pageX,
        mouseY: event.pageY,
        svgX: x,
        svgY: y,
        iso3: countryProps.iso3,
        name: countryProps.name,
      });
    };

    const removeTooltip = () => onCountryHover(null);
    const isSelected = selectedCountryIso3 === countryProps.iso3;

    return (
      <React.Fragment key={countryProps.iso3}>
        <path
          className={classes.countryOutline}
          d={path(feature)!}
          style={style}
        ></path>

        <path
          onMouseMove={showTooltip}
          onClick={(event) => {
            const svg = svgRef.current;
            if (!svg) {
              return;
            }
            const { x, y } = mouseEventToDomPoint(event, svg);

            onCountryClick({
              mouseX: event.pageX,
              mouseY: event.pageY,
              svgX: x,
              svgY: y,
              iso3: countryProps.iso3,
              name: countryProps.name,
            });
          }}
          onMouseLeave={removeTooltip}
          className={classnames(classes.countryOutline, {
            [classes.selectedCountry]: isSelected,
          })}
          d={path(feature)!}
          style={style}
        ></path>
      </React.Fragment>
    );
  }
);

export default IdRateMap;
