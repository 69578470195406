import React, { useMemo } from "react";
import classes from "./Selector.module.css";

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectorProps = {
  value: string;
  options: SelectOption[];
  onChange(selectedValue: string): void;

  name?: string;
};

function Selector({ value, options, name, onChange }: SelectorProps) {
  const labelByValue = useMemo(() => {
    return options.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.value]: cur.label,
      }),
      {} as { [value: string]: string }
    );
  }, [options]);

  return (
    <div className={classes.container}>
      <div className={classes.textOverlay}>
        <span className={classes.overlayContent}>
          {labelByValue[value]} <DropDownIcon />
        </span>
      </div>
      <select
        style={{ width: labelByValue[value].length * 10 }}
        className={classes.selector}
        value={value}
        name={name}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function DropDownIcon() {
  return (
    <svg
      className={classes.icon}
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.5L7.5 6L13.5 1.5" stroke="black" strokeWidth="1.5" />
    </svg>
  );
}

export default React.memo(Selector);
