import { WeekRange } from "../api/dataTypes";

export default function formatWeekRange(range: WeekRange) {
  const from = new Date(range.from);
  const to = new Date(range.to);
  const formatedTo = formatDayAndMonth(to);

  return `${formatDayAndMonth(from)} - ${formatedTo} ${to.getUTCFullYear()}`;
}

function formatDayAndMonth(d: Date) {
  const month = d.toLocaleString("default", { month: "long", timeZone: "UTC" });
  return `${d.getUTCDate()} ${month}`;
}
